import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

export function Card({
  itemId,
  selected,
  onClick,
  name,
  image,
  level
}: {
  itemId: string;
  selected: boolean;
  onClick: Function;
  name: String;
  image: String;
  level: number;
}) {
  const visibility = React.useContext(VisibilityContext);

  // eslint-disable-next-line
  const visible = visibility.isItemVisible(itemId);

  return (
    <div
    className="singleUser"
    onClick={() => onClick(visibility)}
    tabIndex={0}
    style={{
      background: selected ? "white" : "none",
      color: selected ? "#1a2b63" : "white"
    }}
    >
        <div className="image" style={{
            backgroundImage:`url(/images/${image}.jpg)`,
            // border: selected ? "1px solid green" : "none"
        }}></div>
        <div className="name">{name}</div>
        <div className="level">{userLevel(level)}</div>
    </div>
  );
}

function userLevel(lvl: number){
    if(lvl === 0) return 'Хэлний бэлтгэл';
    else if(lvl === 1) return '1-р курс';
    else if(lvl === 2) return '2-р курс';
    else if(lvl === 3) return '3-р курс';
    else if(lvl === 4) return '4-р курс';
    else return 'Тодорхоогүй';
}
