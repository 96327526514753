import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import './styles/main.scss';
import types from './types.json';
import usersListJS from './users.json';
import axios from 'axios';

import useDrag from "./useDrag";
import { LeftArrow, RightArrow } from "./arrows";
import { Card } from "./card";
import { SelectedUser } from './SelectedUser';

import {
  ScrollMenu,
  VisibilityContext,
  getItemsPos,
  slidingWindow
} from "react-horizontal-scrolling-menu";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const client = axios.create({
  baseURL: "https://pwa.mn/api" 
});

function Nomination() {
  const localList = JSON.parse(localStorage.getItem("selectedUsers"));
  const navigate = useNavigate();
  let { id } = useParams();
  const { dragStart, dragStop, dragMove, dragging } = useDrag();

  // eslint-disable-next-line
  const type = types.find(d => d.id.toString() === id);
  // eslint-disable-next-line
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState<string>("");
  // eslint-disable-next-line
  const [position, setPosition] = useState(0);
  const [prevSelected, setPrevSelected] = useState(localList);

  useEffect(() => {

    return navigate('/');
    // eslint-disable-next-line
    if(localStorage.getItem("selectedUsers")){
      setPrevSelected(JSON.parse(localStorage.getItem("selectedUsers")));
    }
    else {
      localStorage.setItem("selectedUsers", JSON.stringify([]));
      setPrevSelected([]);
    }
    
    const userList = getUsers(prevSelected);
    setUsers(userList);
    // eslint-disable-next-line
  }, []);


  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
    ev: React.MouseEvent
  ) =>
    dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

  const handleItemClick = (itemId: string) => ({ getItemById, scrollToItem }: scrollVisibilityApiType) => {
    if (dragging) { 
      return false;     
    }
    setSelected(selected !== itemId ? itemId : "");
    // NOTE: for center items
    scrollToItem(getItemById(itemId), "smooth", "center", "nearest");
  };

  const previous = () => {
    const realId = parseInt(id);
    if(realId !== 1){
      navigate(`/nomination/${realId-1}`, { replace: true });
    }
    else {
      navigate('/');
    }
  };

  const submit = () => {
    prevSelected.push(parseInt(selected));
    setPrevSelected(prevSelected);
    localStorage.setItem("selectedUsers", JSON.stringify(prevSelected));
    
    
    const userList = getUsers(prevSelected);
    setUsers(userList);
    const realId = parseInt(id);
    if(realId < 31){
      setSelected(null);
      navigate(`/nomination/${realId+1}`, { replace: true });
    }
    else {
      client.post('/newresult', {
            data: prevSelected,
         })
         .then((response) => {
            console.log(response);
         });
      navigate('/success');
    }

  }

  return (
    <div className="nomination">
      <div className="reu"><img src="/images/rea-logo.svg" alt="reu"/></div>
      <div className="back" onClick={previous}>
        <img src="/images/back.svg" alt="back"/>
      </div>
      { prevSelected.length >= 31
        ? <div className="nominations">
            <div className="row">
              <div className="container">
                <div className="title">
                  Та саналаа аль хэдийн өгсөн байна 😉
                </div>
              </div>
            </div>
          </div>
        : <>
            { parseInt(id) <= prevSelected.length 
            ? <div className="already">
                <div className="row">
                  <SelectedUser type={type.name} id={parseInt(id)}/>
                  <Link to={`/nomination/${prevSelected.length+1}`}><div className="button">Үргэлжлүүлэх</div></Link>
                </div>
              </div>
            : <div className="nominations">
                <div className="row">
                  <div className="container">
                    <div className="icon">
                      <img src="/images/icon.svg" alt="icon"/>
                    </div>
                    <div className="title">
                      Хамгийн <span>{type.name}</span>
                    </div>

                    <div className="people">
                      <div onMouseLeave={dragStop}>
                        <ScrollMenu
                          LeftArrow={LeftArrow}
                          RightArrow={RightArrow}
                          onWheel={onWheel}
                          onMouseDown={() => dragStart}
                          onMouseUp={({
                            getItemById,
                            scrollToItem,
                            visibleItems
                          }: scrollVisibilityApiType) => () => {
                            // NOTE: for center items
                            dragStop();
                            const { center } = getItemsPos(visibleItems);
                            scrollToItem(getItemById(center), "smooth", "center");
                          }}
                          options={{ throttle: 0 }} // NOTE: for center items
                          onMouseMove={handleDrag}
                        >
                          {users.map((user) => (
                            <Card
                              itemId={user.id.toString()}
                              name={user.name}
                              image={user.image}
                              level={user.level}
                              key={user.id}
                              onClick={handleItemClick(user.id.toString())}
                              selected={user.id.toString() === selected}
                            />
                          ))}
                        </ScrollMenu>
                      </div>
                    </div>
                    

                    {/* <div ref={ref} className="people">
                        {users.map(user => 
                            <div key={user} className="singleUser">{userSingle(user)}</div>
                        )}
                    </div> */}
                    <div className="btn">
                      {selected 
                        ? <div className="button" onClick={submit}>Сонгох</div>
                        : <div className="button disabled">Сонгох</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </>
      }
      
    </div>
  );
}


function onWheel(
  { getItemById, items, visibleItems, scrollToItem }: scrollVisibilityApiType,
  ev: React.WheelEvent
): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    // NOTE: for center items
    const nextGroupItems = slidingWindow(
      items.toItemsKeys(),
      visibleItems
    ).next();
    const { center } = getItemsPos(nextGroupItems);
    scrollToItem(getItemById(center), "smooth", "center");
  } else if (ev.deltaY > 0) {
    const prevGroupItems = slidingWindow(
      items.toItemsKeys(),
      visibleItems
    ).prev();
    const { center } = getItemsPos(prevGroupItems);
    scrollToItem(getItemById(center), "smooth", "center");
  }
}


function getUsers(selected:number[]) {
  const array = usersListJS.filter((user) => {
      return !selected.includes(user.id);
  });
  // const newArray = shuffle(array);
  return array;
}


export default Nomination;
