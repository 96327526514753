import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles/main.scss';

function Home() {

    // eslint-disable-next-line
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if(!localStorage.getItem("selectedUsers")){
      localStorage.setItem("selectedUsers", JSON.stringify([]));
    }
    else {
      setSelected(JSON.parse(localStorage.getItem("selectedUsers")))
    }
  }, []);

  return (
    <div className="home">
      <div className="reu"><img src="/images/rea-logo.svg" alt="reu"/></div>
      <div className="welcome">
        <div className="row">
          <div className="container">
            <div className="icon">
              <img src="/images/icon.svg" alt="icon"/>
            </div>
            <div className="title">
              <span>Хамгийн Хамгийн</span> шалгаруулах номинаци амжилттай дууслаа 😇
            </div>
            <Link to="/select"><div className="button" style={{marginTop: 20}}>Сэтгэлийн үг</div></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
