import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './styles/main.scss';

function Code() {
  const navigate = useNavigate();

  const [input, setInput] = useState("");

  const handleInput = (event) => {
    setInput(event.target.value);
  }

  const handleCode = async () => {
    if(!input) return alert("Кодоо оруулна уу :(");
    const response = await fetch('https://pwa.mn/api/code/'+input.toUpperCase());
    const resultsData = await response.json();

    if(!resultsData.status){
        navigate('/profile/'+input.toUpperCase());
    }
    else {
        alert("Код буруу байна :(");
    }
  }   

  return (
    <div className="home">
      <div className="reu"><img src="/images/rea-logo.svg" alt="reu"/></div>
      <div className="welcome">
        <div className="row">
          <div className="container">
            <div className="title">
              <span>Кодоо оруулна уу</span>
            </div>
            <div className="input">
              <input type="text" onChange={handleInput}/>
            </div>
            <div className='writeBtn'>
            <div className="button" onClick={handleCode}>Нэвтрэх</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Code;
