import React from 'react';
import './styles/main.scss';

function Reset() {
  
    if(localStorage.getItem("selectedUsers")){
        localStorage.removeItem("selectedUsers");
    }

  return (
    <div className="home">
      <div className="welcome">
        <div className="row">
          <div className="container">
            <div className="icon">
              <img src="/images/icon.svg" alt="icon"/>
            </div>
            <div className="title">
              <span>Амжилттай reset хийдлээ!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reset;
