import React from 'react'
import usersList from './users.json'

export function SelectedUser({type, id} : {type: String, id: number}){
    const users = JSON.parse(localStorage.getItem("selectedUsers"));
    var userID = users[id-1];
    var userData = usersList.find(u => u.id === userID);
    return (
      <>
        <div className="image" style={{ backgroundImage:`url(/images/${userData.image}.jpg)` }}></div>
        <div className="text">
          Хамгийн {type} <span>{userData.name}</span>
        </div>
      </>
    )
};