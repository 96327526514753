import React, { useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import './styles/main.scss';

function Success() {
  const navigate = useNavigate();

  useEffect(() => {
    return navigate('/');
    // eslint-disable-next-line
    if(!localStorage.getItem("selectedUsers")){
      navigate('/', { replace: true });
    }
    else {
      const data = JSON.parse(localStorage.getItem("selectedUsers"));
      console.log(data);
      if(data.length < 31) {
        navigate('/', { replace: true });
      }
    }
  })

  return (
    <div className="home">
      <div className="reu"><img src="/images/rea-logo.svg" alt="reu"/></div>
      <div className="welcome">
        <div className="row">
          <div className="container">
            <div className="title">
              <span>Амжилттай илгэлээ 😘</span>
            </div>
            <div className="writeText">
              Шинэ жилийн party мань 12-р сарын 17-ны 18:00 цагаас болох байгаа шүү. Аа нэрээ тийн.
              Та доорх товч дээр дараад сэтгэлийн үг ээ хүссэн хүндээ илгээх боломжтой шүү 😇
            </div>
            <div className='writeBtn'>
            <Link to="/select"><div className="button">Сэтгэлийн үг</div></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
