import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import fetch from "node-fetch";
import types from "./types.json";
import users from "./users.json";
import './styles/main.scss';

function Admin() {
  const navigate = useNavigate();

  const [results, setResults] = useState([]);

  useEffect(() => {
    if(!localStorage.getItem("admin") && localStorage.getItem("admin") !== "true"){
      navigate('/login', { replace: true });
    }
    getAndCalculateData();
    // eslint-disable-next-line
  }, []);


  const getAndCalculateData = async () => {
    const response = await fetch('https://pwa.mn/api/results');
    const resultsData = await response.json();
    var usersList = [];
    
    for(var i=1;i<=32;i++){
        usersList[i] = true;
    }
    // eslint-disable-next-line
    var resultArr = new Array();
    // eslint-disable-next-line
    await types.map((type, index) => {
        resultArr[index] = [];
    });
    // eslint-disable-next-line
    await resultsData.map((single, xIndex) => {
        var data = single.data;
        data.map((d, yIndex) => resultArr[yIndex].push(d));
    });

    // eslint-disable-next-line
    var resultCount = new Array();
    // eslint-disable-next-line
    await resultArr.map(data => {
        var counts = {};
        data.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        resultCount.push(counts);
    });


    var resultFinal = [];
    // eslint-disable-next-line
    await resultCount.map((data, id) => {
        var values = Object.values(data);
        var keys = Object.keys(data);
        var max = 0;
        var pos;
        // eslint-disable-next-line
        values.map((d, index) => {
            if(parseInt(d.toString()) > max && usersList[parseInt(keys[index])]) {
                max = parseInt(d.toString());
                pos = index;
            }
        });
        usersList[parseInt(keys[pos])] = false;
        resultFinal.push({id: id+1, user: parseInt(keys[pos]), score: max});
    });

    setResults(resultFinal);
  }
  


  return (
    <div className="admin_page">
        <div className="admin">
            <div className="title">
                <span>Админ панел</span>
            </div>
            { results.length > 0 
            ? <div className="result">
                    { results.map(result => (
                        <Card key={result.id} data={result}/>
                    ))}
                </div>
            : <div className="loading"><img src="/images/loading.svg" alt="loading"/></div>
            }
      </div>
    </div>
  );
}

function Card(props) {
    const data = props.data;
    const user = users.find(u => u.id.toString() === data.user.toString());
    const type = types.find(t => t.id.toString() === data.id.toString());
    return (
        <div className="card">
            <div className="image" style={{
                backgroundImage:`url(/images/${user.image}.jpg)`
            }}></div>
            <div className="info">
                <div className="type">
                    Хамгийн <span>{type.name}</span> 👑
                </div>
                <div className="name">
                    {user.name}
                </div>
                <div className="level">
                    {userLevel(user.level)}
                </div>
            </div>
        </div>
    );
};

function userLevel(lvl: number){
    if(lvl === 0) return 'Хэлний бэлтгэл';
    else if(lvl === 1) return '1-р курс';
    else if(lvl === 2) return '2-р курс';
    else if(lvl === 3) return '3-р курс';
    else if(lvl === 4) return '4-р курс';
    else return 'Тодорхоогүй';
}

export default Admin;
