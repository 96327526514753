import React from 'react';
import './styles/main.scss';

function NotFound() {
  return (
    <div className="notfound">
      <div className="welcome">
        <div className="row">
          <div className="container">
            <div className="icon">
              <img src="/images/icon.svg" alt="icon"/>
            </div>
            <div className="title">
              Уучлаарай, таны хайсан зүйл олдсонгүй 😢
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
