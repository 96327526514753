import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import fetch from "node-fetch";
import './styles/main.scss';

function Give() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await fetch('https://pwa.mn/api/users');
    const resultsData = await response.json();

    setUsers(resultsData);
  }

  return (
    <div className="admin_page">
        <div className="admin">
            <div className="title">
                <span>Код өгөх хэсэг</span>
            </div>
            { users.length > 0 
            ? <div className="result">
                    { users.map(result => (
                        <Card key={result.id} data={result}/>
                    ))}
                </div>
            : <div className="loading"><img src="/images/loading.svg" alt="loading"/></div>
            }
      </div>
    </div>
  );
}

function Card(props) {
    const user = props.data;
    return (
        <>
            <Link to={`/qr/${user.id}`}>
                <div className="card">
                <div className="image" style={{
                    backgroundImage:`url(/images/${user.image}.jpg)`
                }}></div>
                <div className="info">
                    <div className="name">
                        {user.name}
                    </div>
                    <div className="level">
                        {userLevel(user.level)}
                    </div>
                </div>
            </div>
            </Link>
        </>
    );
};

function userLevel(lvl: number){
    if(lvl === 0) return 'Хэлний бэлтгэл';
    else if(lvl === 1) return '1-р курс';
    else if(lvl === 2) return '2-р курс';
    else if(lvl === 3) return '3-р курс';
    else if(lvl === 4) return '4-р курс';
    else return 'Тодорхоогүй';
}

export default Give;
