import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import fetch from "node-fetch";
import './styles/main.scss';
import { QRCodeSVG } from 'qrcode.react';

function Qr() {
  var { id } = useParams();

  const [user, setUser] = useState(null);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    const response = await fetch('https://pwa.mn/api/user/'+id.toString());
    const resultsData = await response.json();

    setUser(resultsData);
  }

  return (
    <div className="qr_page">
        <div className="qr">
            <div className="title">
                <span>Код</span>
            </div>
            { user 
            ?   <div className="result">
                    <div className="card">
                        <div className="name">
                            {user.name}
                        </div>
                        <div className="level">
                            {userLevel(user.level)}
                        </div>
                        <div className="qrcode">
                            <QRCodeSVG value={`https://rate.mn/profile/${user.code}`} size={200}/>
                        </div>
                        <div className="code">
                            {user.code}
                        </div>
                        <div className="link">
                        rate.mn/code
                        </div>
                    </div>
                </div>
            : <div className="loading"><img src="/images/loading.svg" alt="loading"/></div>
            }
      </div>
    </div>
  );
}

function userLevel(lvl: number){
    if(lvl === 0) return 'Хэлний бэлтгэл';
    else if(lvl === 1) return '1-р курс';
    else if(lvl === 2) return '2-р курс';
    else if(lvl === 3) return '3-р курс';
    else if(lvl === 4) return '4-р курс';
    else return 'Тодорхоогүй';
}

export default Qr;
