import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import './styles/main.scss';
import types from './types.json';
import usersListJS from './users.json';

import useDrag from "./useDrag";
import { LeftArrow, RightArrow } from "./arrows";
import { Card } from "./card";

import {
  ScrollMenu,
  VisibilityContext,
  getItemsPos,
  slidingWindow
} from "react-horizontal-scrolling-menu";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function Select() {
    const localList = JSON.parse(localStorage.getItem("selectedUsers"));
    const navigate = useNavigate();
    let { id } = useParams();
    const { dragStart, dragStop, dragMove, dragging } = useDrag();
  
    // eslint-disable-next-line
    const type = types.find(d => d.id.toString() === id);
    // eslint-disable-next-line
    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState<string>("");
    // eslint-disable-next-line
    const [position, setPosition] = useState(0);
    // eslint-disable-next-line
    const [prevSelected, setPrevSelected] = useState(localList);
  
    useEffect(() => {
      setUsers(usersListJS);
      // eslint-disable-next-line
    }, []);
  
  
    const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
      ev: React.MouseEvent
    ) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });
  
    const handleItemClick = (itemId: string) => ({ getItemById, scrollToItem }: scrollVisibilityApiType) => {
      if (dragging) { 
        return false;     
      }
      setSelected(selected !== itemId ? itemId : "");
      scrollToItem(getItemById(itemId), "smooth", "center", "nearest");
    };
  
    const previous = () => {
        navigate('/');
    };
  
    const submit = () => {
        localStorage.setItem("writeUser", selected);
        navigate(`/write`, { replace: true });
    }
  
    return (
      <div className="nomination">
        <div className="reu"><img src="/images/rea-logo.svg" alt="reu"/></div>
        <div className="back" onClick={previous}>
          <img src="/images/back.svg" alt="back"/>
        </div>
        <div className="nominations">
            <div className="row">
            <div className="container">
                <div className="title">
                    Сэтгэлийн үгээ илгээх хүнээ сонгоно уу
                </div>

                <div className="people">
                <div onMouseLeave={dragStop}>
                    <ScrollMenu
                    LeftArrow={LeftArrow}
                    RightArrow={RightArrow}
                    onWheel={onWheel}
                    onMouseDown={() => dragStart}
                    onMouseUp={({
                        getItemById,
                        scrollToItem,
                        visibleItems
                    }: scrollVisibilityApiType) => () => {
                        // NOTE: for center items
                        dragStop();
                        const { center } = getItemsPos(visibleItems);
                        scrollToItem(getItemById(center), "smooth", "center");
                    }}
                    options={{ throttle: 0 }} // NOTE: for center items
                    onMouseMove={handleDrag}
                    >
                    {users.map((user) => (
                        <Card
                        itemId={user.id.toString()}
                        name={user.name}
                        image={user.image}
                        level={user.level}
                        key={user.id}
                        onClick={handleItemClick(user.id.toString())}
                        selected={user.id.toString() === selected}
                        />
                    ))}
                    </ScrollMenu>
                </div>
                </div>
                <div className="btn">
                {selected 
                    ? <div className="button" onClick={submit}>Сонгох</div>
                    : <div className="button disabled">Сонгох</div>
                }
                </div>
            </div>
            </div>
        </div>

      </div>
    );
}


function onWheel(
{ getItemById, items, visibleItems, scrollToItem }: scrollVisibilityApiType,
ev: React.WheelEvent
): void {
const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

if (isThouchpad) {
    ev.stopPropagation();
    return;
}

if (ev.deltaY < 0) {
    // NOTE: for center items
    const nextGroupItems = slidingWindow(
    items.toItemsKeys(),
    visibleItems
    ).next();
    const { center } = getItemsPos(nextGroupItems);
    scrollToItem(getItemById(center), "smooth", "center");
} else if (ev.deltaY > 0) {
    const prevGroupItems = slidingWindow(
    items.toItemsKeys(),
    visibleItems
    ).prev();
    const { center } = getItemsPos(prevGroupItems);
    scrollToItem(getItemById(center), "smooth", "center");
}
}

export default Select;
