import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import './styles/main.scss';
import types from "./types.json";
import fetch from "node-fetch";

function Profile() {
  var { id } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [results, setResults] = useState([]);
  const [nominees, setNominees] = useState([]);
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    getAndCalculateData();
    checkCode();
    // eslint-disable-next-line
  }, []);

  const getAndCalculateData = async () => {
    const response = await fetch('https://pwa.mn/api/results');
    const resultsData = await response.json();
    var usersList = [];
    
    for(var i=1;i<=32;i++){
        usersList[i] = true;
    }

    // eslint-disable-next-line
    var resultArr = new Array();
    // eslint-disable-next-line
    await types.map((type, index) => {
        resultArr[index] = [];
    });
    // eslint-disable-next-line
    await resultsData.map((single, xIndex) => {
        var data = single.data;
        data.map((d, yIndex) => resultArr[yIndex].push(d));
    });
    // eslint-disable-next-line
    var resultCount = new Array();
    // eslint-disable-next-line
    await resultArr.map(data => {
        var counts = {};
        data.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        resultCount.push(counts);
    });


    var resultFinal = [];
    // eslint-disable-next-line
    await resultCount.map((data, id) => {
        var values = Object.values(data);
        var keys = Object.keys(data);
        var max = 0;
        var pos;
        // eslint-disable-next-line
        values.map((d, index) => {
            if(parseInt(d.toString()) > max && usersList[parseInt(keys[index])]) {
                max = parseInt(d.toString());
                pos = index;
            }
        });
        usersList[parseInt(keys[pos])] = false;
        resultFinal.push({id: id+1, user: parseInt(keys[pos]), score: max});
    });

    var userResult = [];
    // eslint-disable-next-line
    await resultFinal.map((data, id) => {
        userResult[data.user] = data.id;
    });

    var setNomineesArr = [];
    // eslint-disable-next-line
    await types.map(t => {
        setNomineesArr[t.id] = t.name;
    });

    setNominees(setNomineesArr);
    setResults(userResult);
  }

  const checkCode = async () => {
    const response = await fetch('https://pwa.mn/api/code/'+id.toLocaleUpperCase());
    const resultsData = await response.json();

    if(!resultsData.status){
        document.title = resultsData.name;
        setUser(resultsData);

        const responseM = await fetch('https://pwa.mn/api/message/'+resultsData.id);
        const resultsDataM = await responseM.json();
        if(!resultsDataM.status){
            setMessages(resultsDataM);
        }
        else {
            setMessages([]);
        }
    }
    else {
        navigate('/code', { replace: true });
    }
  }

  return (
    <div className="profile_page">
      <div className="reu"><img src="/images/rea-logo.svg" alt="reu"/></div>
      <div className="profile">
        {user && results && messages
        ? <>
            <div className="userCard">
                <div className="image" style={{
                    backgroundImage:`url(/images/${user.image}.jpg)`
                }}><img src="/images/king.svg" alt="king"/></div>
                <div className="name">
                    {user.name}
                </div>
                <div className="level">
                    {userLevel(user.level)}
                </div>
                <div className="type">
                   Хамгийн хамгийн <br/><span>{nominees[results[user.id]]}</span>
                </div>
            </div>

            <div className="congrats">
                Сайн уу, {user.name}? Шинэ жилийн үдэшлэг таалагдаж байгаа биз дээ? 😇 Юуны өмнө <span>Хамгийн хамгийн {nominees[results[user.id]]}</span> номинацид шалгарсанд баяр хүргэе! 🥳🎉🎈
                <br/><br/>
                Харин одоо бусадаас ирсэн сэтгэлийн үгсийг унших цаг ирлээ. Танд нийт <span>{messages.length} захиа</span> ирсэн ба бичсэн бүх хүмүүс нэрээ нууцалсан тул та хэнээс ирсэнийг мэдэх боломжгүй байгаа 😉. За тэгээд захиадаалаа уншаад шинэ жилээ супер тэмдэглээрэй 😘
            </div>

            <div className="messages">
                {messages.map(m => (
                    <MessageCard key={m._id} message={m.message}/>
                ))}
            </div>

            <div className="newyear">
                HAPPY NEW YEAR !!!
            </div>
            <div className="developed">
                
                Developer with ❤️ by <a href="https://sanchir.dev/" target="_blank" rel="noreferrer">Sanchir</a>
            </div>
          </>
        : <div className="loading"><img src="/images/loading.svg" alt="loading"/></div>
        }
      </div>
    </div>
  );
}

function MessageCard(props){
    const message = props.message;
    return (
        <div className="card">
            {message}
        </div>
    );
}

function userLevel(lvl: number){
    if(lvl === 0) return 'Хэлний бэлтгэл';
    else if(lvl === 1) return '1-р курс';
    else if(lvl === 2) return '2-р курс';
    else if(lvl === 3) return '3-р курс';
    else if(lvl === 4) return '4-р курс';
    else return 'Тодорхоогүй';
}


export default Profile;
