import React from 'react';
import { Link } from 'react-router-dom';
import './styles/main.scss';

function WriteSuccess() {

  return (
    <div className="home">
      <div className="reu"><img src="/images/rea-logo.svg" alt="reu"/></div>
      <div className="write">
        <div className="row">
          <div className="container">
            <div className="title">Амжилттай илгээгдлээ! 😇</div>
            <div className="againButton">
            <Link to="/select"><div className="button">Дахин илгээх</div></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WriteSuccess;
