import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import Nomination from './Nomination';
import NotFound from './NotFound';
import Success from './Success';
import Reset from './Reset';
import Write from './Write';
import Select from './Select';
import WriteSuccess from './WriteSuccess';
import Login from './Login';
import Admin from './Admin';
import Profile from './Profile';
import Code from './Code';
import Give from './Give';
import Qr from './Qr';
import './styles/main.scss';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/nomination/:id' element={<Nomination />}></Route>
        <Route path='/profile/:id' element={<Profile />}></Route>
        <Route path='/qr/:id' element={<Qr />}></Route>
        <Route path='/success' element={<Success />}></Route>
        <Route path='/reset' element={<Reset />}></Route>
        <Route path='/select' element={<Select />}></Route>
        <Route path='/write' element={<Write />}></Route>
        <Route path='/writesuccess' element={<WriteSuccess />}></Route>
        <Route path='/login' element={<Login />}></Route>
        <Route path='/admin' element={<Admin />}></Route>
        <Route path='/code' element={<Code />}></Route>
        <Route path='/give' element={<Give />}></Route>
        <Route path='*' element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
