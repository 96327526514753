import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/main.scss';
import userList from './users.json';
import fetch from 'node-fetch';

function Write() {
  const navigate = useNavigate();
  const selected = JSON.parse(localStorage.getItem("writeUser"));
  const user = userList.find(u => u.id === parseInt(selected));

  const [message, setMessage] = useState();

  useEffect(() => {
    if(!localStorage.getItem("writeUser")){
      navigate(`/select`, { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    setMessage(event.target.value);
  }

  const handleSubmit = async (event) => {
    try {
      const body = {user: user.id, message: message};

      const response = await fetch('https://pwa.mn/api/newmessage', {
        method: 'post',
        body: JSON.stringify(body),
        headers: {'Content-Type': 'application/json'}
      });
      const data = await response.json();

      if(data) {
        navigate(`/writesuccess`, { replace: true });
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  const previous = () => {
    navigate(`/select`, { replace: true });
  };

  return (
    <div className="home">
      <div className="back" onClick={previous}>
        <img src="/images/back.svg" alt="back"/>
      </div>
      <div className="reu"><img src="/images/rea-logo.svg" alt="reu"/></div>
      <div className="write">
        <div className="row">
          <div className="container">
            <div className="image" style={{
              backgroundImage:`url(/images/${user.image}.jpg)`
            }}></div>

            <div className="textTitle"><span>{user.name}</span>-д илгээх зурвасаа доор бичнэ үү</div>
            <textarea value={message} onChange={handleChange}/>
            <br/>
            <div className="button" onClick={handleSubmit}>Илгээх</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Write;
