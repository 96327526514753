import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './styles/main.scss';

function Login() {
  const navigate = useNavigate();

  const [input, setInput] = useState();

  const handleInput = (event) => {
    setInput(event.target.value);
  }

  const handleCode = () => {
    if(input === "reu2022"){
      localStorage.setItem("admin", "true");
      navigate('/admin', { replace: true });
    }
    else {
      alert("Код буруу байна :(");
    }
  }   

  return (
    <div className="home">
      <div className="reu"><img src="/images/rea-logo.svg" alt="reu"/></div>
      <div className="welcome">
        <div className="row">
          <div className="container">
            <div className="title">
              <span>Админ панел</span>
            </div>
            <div className="input">
              <input type="text" onChange={handleInput}/>
            </div>
            <div className='writeBtn'>
            <div className="button" onClick={handleCode}>Нэвтрэх</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
